import _, { set } from 'lodash'
import { useEffect, useState } from 'react'
import { usePacket, useProject, useTrackerDropdowns, useUpdatePacket } from 'tracker/api'
import { UspsApi } from 'tracker/api/usps.api'
import { usePrevious } from 'hooks'
import { useQueries } from '@tanstack/react-query'
import { uniq } from 'lodash'
import { validateCity } from 'tracker/file/project/associates/utils.js/associatesUtils'

// The purpose of this hook is to provide a simpler interface for handling
//     recipients in the packet AND associates in the project
export const usePacketRecipients = (projectId, packetId, preferencesState, isModalOpen) => {
    // Project API
    const { data: project, ...projectApi } = useProject(
        { projectId },
        { enabled: !!(projectId && isModalOpen) }
    )
    // Packet API
    const { data: packet, ...packetApi } = usePacket(packetId, {
        enabled: !!packetId && isModalOpen,
    })
    // Update Packet API
    const { mutate: updatePacket, ...updatePacketApi } = useUpdatePacket(projectId)

    const { data: dropdowns } = useTrackerDropdowns()
    const defaultUSPostage = dropdowns?.['docgen.postage']?.find(
        (postage) => postage.code === 'CERR'
    )?.code
    const defaultNonUSPostage = dropdowns?.['docgen.postage']?.find(
        (postage) => postage.code === 'FC'
    )?.code

    const [recipients, setRecipients] = useState([])
    const selectedRecipients = recipients?.filter(({ selected }) => selected)
    const USOnlyPostageCodes = ['CERR', 'CNRR', 'CRD']

    const zipLookupQueries = useQueries({
        queries: project?.associates
            ? Object.values(project?.associates)
                  ?.filter(
                      (associate) =>
                          !associate?.is_deleted &&
                          isAdddressFilled(associate) &&
                          dropdowns?.['state']?.find(({ code }) => code === associate?.state)
                              ?.country == 'USA'
                  )
                  ?.map((associate) => {
                      const zip5 = associate?.zip?.trim()?.substring(0, 5)
                      return {
                          queryKey: ['zipLookup', zip5],
                          queryFn: () => UspsApi.lookupCityStateByZip(zip5),
                          enabled: true,
                          cacheTime: Infinity,
                          staleTime: Infinity,
                      }
                  })
            : [],
    })
    const zipLookups = zipLookupQueries.reduce((acc, query) => {
        if (query?.data && query?.data?.Zip5) return { ...acc, [query?.data?.Zip5]: query.data }
        else return acc
    }, {})

    useEffect(async () => {
        let associates = {}

        // First, get all associates from the project and determine if they are valid
        if (project?.associates) {
            associates = project?.associates

            Object.values(project?.associates)
                .filter((associate) => !associate?.is_deleted)
                .forEach((associate) => {
                    const isAddressFilled = isAdddressFilled(associate)
                    const zipLookup = zipLookups?.[associate?.zip?.substring(0, 5)]
                    const isUsa =
                        dropdowns?.['state']?.find(({ code }) => code === associate?.state)
                            ?.country === 'USA'
                    const cityError = validateCity(associate?.city)
                    let isZipFormatValid = true
                    let isStateZipValid = false
                    if (isAddressFilled && zipLookup) {
                        isStateZipValid =
                            zipLookup.State?.toUpperCase()?.trim() ===
                            associate?.state?.toUpperCase()?.trim()
                    }
                    // If not USA, assume zip valid
                    if (!isUsa) isStateZipValid = true
                    else isZipFormatValid = /^\d{5}(?:-\d{4})?$/.test(associate?.zip?.trim())

                    associates[associate?._id] = {
                        ...associate,
                        isAddressValid: !!(
                            isAddressFilled &&
                            isStateZipValid &&
                            isZipFormatValid &&
                            !cityError
                        ),
                        isAddressFilled,
                        isZipFormatValid,
                        isStateZipValid,
                        addressStatusMessage: !isAddressFilled
                            ? 'Invalid address'
                            : !isZipFormatValid
                            ? 'Invalid zip code'
                            : !isStateZipValid
                            ? 'Invalid state-zip combo'
                            : cityError
                            ? cityError
                            : null,
                    }
                })
        }

        // If packet has yet to be created, create recipients from project associates
        if (!packetId && associates) {
            setRecipients(
                Object.values(associates)
                    ?.filter((assoc) => !assoc?.is_deleted)
                    ?.map((assoc) => {
                        const recipient = recipients?.find(({ _id }) => _id === assoc?._id)
                        return {
                            ...assoc,
                            postage_type: recipient?.postage_type
                                ? recipient?.postage_type
                                : dropdowns?.['state']?.find(({ code }) => code === assoc?.state)
                                      ?.country === 'USA'
                                ? defaultUSPostage
                                : defaultNonUSPostage,
                            selected:
                                recipient?.selected != null || recipient?.selected != undefined
                                    ? recipient?.selected
                                    : true,
                        }
                    })
            )
        }

        // If packet has been created, merge recipients from packet and project associates
        if (packet?.recipients && associates) {
            let packetRecipients = packet?.recipients
            for (const key in packetRecipients) {
                packetRecipients[key].selected = true
            }

            let mergedObjects = _.merge({}, packetRecipients, associates)
            for (const key in mergedObjects) {
                mergedObjects[key].postage_type = mergedObjects[key]?.postage_type
                    ? mergedObjects[key]?.postage_type
                    : dropdowns?.['state']?.find(({ code }) => code === mergedObjects[key]?.state)
                          ?.country === 'USA'
                    ? defaultUSPostage
                    : defaultNonUSPostage
            }
            setRecipients(Object.values(mergedObjects)?.filter((assoc) => !assoc?.is_deleted))
        }
    }, [
        isModalOpen,
        packetId,
        packet?.recipients,
        project?.associates,
        defaultUSPostage,
        defaultNonUSPostage,
    ])

    function updateAllRecipientsPostage(postage) {
        const recipientPayload = recipients?.map((recipient) => ({
            ...recipient,
            // If postage type is US only and the recipient is not in the US, keep the original postage type
            postage_type: !USOnlyPostageCodes.includes(postage)
                ? postage
                : dropdowns?.['state']?.find(({ code }) => code === recipient?.state)?.country ===
                  'USA'
                ? postage
                : recipient?.postage_type,
        }))
        const newSpecialHandling = getSuggestedSpecialHandling(recipientPayload, dropdowns)

        setRecipients(recipientPayload)
        preferencesState.updateOptions({ special_handling: newSpecialHandling })
        if (packetId) {
            updatePacket({
                packetId,
                data: {
                    recipients: recipientPayload
                        ?.filter(({ selected }) => selected)
                        ?.reduce(
                            (acc, recipient) => ({
                                ...acc,
                                [recipient?.associate_id]: recipient,
                            }),
                            {}
                        ),
                    options: {
                        ...preferencesState?.options,
                        special_handling: newSpecialHandling,
                    },
                },
            })
        }
    }

    function updateRecipientPostage(associateId, postage) {
        const newRecipients = recipients?.map((recipient) =>
            recipient._id === associateId ? { ...recipient, postage_type: postage } : recipient
        )
        const newSpecialHandling = getSuggestedSpecialHandling(newRecipients, dropdowns)

        setRecipients(newRecipients)
        preferencesState.updateOptions({ special_handling: newSpecialHandling })
        if (packetId) {
            const recipient = recipients.find(({ _id }) => _id === associateId)
            updatePacket({
                packetId,
                data: {
                    recipients: {
                        [associateId]: {
                            associate_id: recipient?._id,
                            id: recipient?.id,
                            postage_type: postage,
                            is_finalized: recipient?.is_finalized,
                        },
                    },
                    options: {
                        ...preferencesState?.options,
                        special_handling: newSpecialHandling,
                    },
                },
            })
        }
    }

    function selectRecipient(associateId, value = true) {
        const newRecipients = recipients?.map((associate) =>
            associate._id === associateId ? { ...associate, selected: value } : associate
        )
        setRecipients(newRecipients)
        const newSpecialHandling = getSuggestedSpecialHandling(newRecipients, dropdowns)
        preferencesState.updateOptions({ special_handling: newSpecialHandling })

        if (packetId) {
            const recipient = recipients.find(({ _id }) => _id === associateId)
            updatePacket({
                packetId,
                data: {
                    recipients: {
                        [associateId]: {
                            associate_id: recipient?._id,
                            id: recipient?.id,
                            postage_type: recipient?.postage_type,
                            is_finalized: recipient?.is_finalized,
                            _delete: !value,
                        },
                    },
                    options: {
                        ...preferencesState?.options,
                        special_handling: newSpecialHandling,
                    },
                },
            })
        }
    }

    function getRecipientsPostObject() {
        return recipients
            ?.filter(({ selected }) => selected)
            ?.reduce(
                (returnObj, recipient) => ({
                    ...returnObj,
                    [recipient?._id]: {
                        associate_id: recipient?._id,
                        postage_type: recipient?.postage_type,
                        is_finalized: recipient?.is_finalized,
                    },
                }),
                {}
            )
    }

    const invalidRecipientsSelected = !!recipients?.find(
        (recipient) =>
            recipient?.selected &&
            !recipient?.isAddressValid &&
            (postagesCantAcceptInvalidAddresses.includes(recipient?.postage_type) ||
                !recipient?.isAddressFilled)
    )
    const noRecipientsSelected = !recipients?.find((recipient) => recipient?.selected)

    const suggestedSpecialHandling = getSuggestedSpecialHandling(recipients, dropdowns)

    return {
        recipients,
        selectedRecipients,
        updateAllRecipientsPostage,
        updateRecipientPostage,
        selectRecipient,
        getRecipientsPostObject,
        invalidRecipientsSelected,
        suggestedSpecialHandling,
        isLoading: updatePacketApi.isLoading,
        USOnlyPostageCodes,
        noRecipientsSelected,
    }
}

const isAdddressFilled = (associate) =>
    associate?.address1 !== undefined &&
    associate?.city !== undefined &&
    associate?.state !== undefined &&
    associate?.zip !== undefined &&
    associate?.address1 &&
    associate?.city &&
    associate?.state &&
    associate?.zip &&
    associate?.address1 !== 'Unknown' &&
    associate?.city !== 'Unknown' &&
    associate?.state !== 'Unknown' &&
    associate?.zip !== 'Unknown'

function getSuggestedSpecialHandling(recipients, dropdowns) {
    const impliedSpecialHandlingSelections = uniq(
        recipients
            ?.filter(
                ({ selected, postage_type }) =>
                    selected &&
                    dropdowns?.['docgen.postage']?.find(({ code }) => code === postage_type)
                        ?.special_handling?.length > 0
            )
            ?.map(
                ({ postage_type }) =>
                    dropdowns?.['docgen.postage']?.find(({ code }) => code === postage_type)
                        ?.special_handling?.[0]?.special_handling_code
            ) ?? []
    )

    let suggestedSpecialHandling = null
    if (impliedSpecialHandlingSelections.length === 1) {
        suggestedSpecialHandling = impliedSpecialHandlingSelections[0]
    } else if (impliedSpecialHandlingSelections.length > 1) {
        suggestedSpecialHandling = 'OT'
    }
    return suggestedSpecialHandling
}

export const postagesCantAcceptInvalidAddresses = [
    'CERR',
    'CNRR',
    'CRD',
    'FX1',
    'FX2',
    'CertifiedERR',
    'StdFedEx',
]
