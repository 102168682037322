import { objToArray } from '../../../../../../../utils'

const dropdowns = typeof window !== 'undefined' ? JSON.parse(localStorage.getItem('dropdowns')) : {}

export const associateRole = (roleCode) =>
    dropdowns?.['associate.role']?.find((role) => role.code == roleCode)

export const associateType = (typeId) =>
    dropdowns?.['associate.type']?.find((type) => type.id == typeId)

export const linkedAssociates = (associates, parentId) =>
    objToArray(associates)?.filter((assoc) => assoc?.parent_associate_id == parentId)

export const associateLabel = (assoc) => {
    let role = associateRole(assoc?.role)?.label
    if (assoc?.role == 'JOINT') role = 'Joint Owner'

    return role ?? assoc?._type_name
}

export const sortAssociates = (associates) => {
    return Object.values(associates)
        ?.filter((assoc) => !assoc?.parent_associate_id)
        ?.map((assoc) => ({
            ...assoc,
            children: Object.values(associates)?.filter((a) => a.parent_associate_id == assoc._id),
        }))
        ?.sort((a, b) => a.type_id - b.type_id)
}

export const getPrimaryForType = (associates, typeCode) =>
    Object.values(associates)?.find(
        ({ _type_code, is_primary }) => _type_code === typeCode && is_primary
    )

export const getDebtor = (associates) =>
    Object.values(associates)?.find(({ is_debtor }) => is_debtor)

export const getAssociateChildren = (associates, parentId) =>
    Object.values(associates)?.filter((assoc) => assoc.parent_associate_id == parentId)

export const validateCity = (value) => {
    if (!value) return ''
    if (/\d/.test(value)) {
        return 'City cannot contain numbers'
    }
    const disallowedWords = ['box', 'suite']
    const lowerValue = value.toLowerCase()

    // Check each disallowed word using word boundaries
    for (const word of disallowedWords) {
        const wordRegex = new RegExp(`\\b${word}\\b`, 'i')
        if (wordRegex.test(lowerValue)) {
            return `City cannot contain the word "${word}"`
        }
    }
    return ''
}
