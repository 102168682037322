import Script from 'next/script'
import { useContext } from 'react'
import { PortalContext } from '../../GlobalState'
import { EmployeeAvatar } from '../../utils'
import BuildVersion from '../../BuildVersion'
import ClearStorageAndReload from '@/components/utils/ClearStoragelAndReload'
import Link from 'next/link'

export default function HeaderBar({ children }) {
    return (
        <>
            <header
                className={
                    'portal-navbar navbar navbar-expand-lg navbar-bordered bg-dark navbar-dark navbar-height w-100'
                }
            >
                <div className='js-mega-menu navbar-nav-wrap hs-menu-initialized hs-menu-horizontal'>
                    {children}
                </div>
            </header>
            <Script
                src={`${process.env.ASSETS_URL}/ncs-bootstrap/front-latest/vendor/hs-mega-menu/dist/hs-mega-menu.min.js`}
                onLoad={() => {
                    setTimeout(() => {
                        new HSMegaMenu('.js-mega-menu', { desktop: { position: 'left' } })
                        // console.log('time out ran')
                    }, 500)
                }}
                onError={(e) => {
                    console.error('Front Mega Menu script failed to load', e)
                }}
            />
        </>
    )
}

// NCS logo
function HeaderBarBrand({ logoSrc, href }) {
    return (
        <div className='navbar-brand-wrapper'>
            <Link href={href} className='navbar-brand'>
                <img src={logoSrc} alt='NCS Logo' style={{ width: '130px' }} />
            </Link>
        </div>
    )
}

// Hamburger menu for when window is too narrow to display HeaderBarMainContent
function HeaderBarToggler() {
    return (
        <button
            type='button'
            className='navbar-toggler collapsed ms-auto'
            data-bs-toggle='collapse'
            data-bs-target='#navbarNavMenuLightEg'
            aria-label='Toggle navigation'
            aria-expanded='false'
            aria-controls='navbarNavMenuLightEg'
        >
            <span className='navbar-toggler-default'>
                <i className='fal fa-list'></i>
            </span>
            <span className='navbar-toggler-toggled'>
                <i className='fal fa-times'></i>
            </span>
        </button>
    )
}

// Main navbar tree
function HeaderBarMainContent({ children }) {
    return (
        <div className='collapse navbar-collapse ms-3' id='navbarNavMenuLightEg'>
            {children}
        </div>
    )
}

// Small icons + profile menu in top right
function HeaderBarSecondaryContent({ children }) {
    return (
        <div className='navbar-nav-wrap-secondary-content'>
            <div className='navbar-nav'>{children}</div>
        </div>
    )
}

// User profile menu in top right
function HeaderBarAvatar(props) {
    const { theme, setTheme, employee, auth, admin } = useContext(PortalContext)
    return (
        <div className='dropdown'>
            <a
                className='navbar-dropdown-account-wrapper'
                id='accountHeaderBarDropdown'
                data-bs-toggle='dropdown'
                aria-expanded='false'
                data-bs-auto-close='outside'
                data-bs-dropdown-animation
            >
                {/* <div className="avatar avatar-sm avatar-primary avatar-circle">
                    <span className="avatar-initials">{employee?.FirstName ? employee?.FirstName[0]+employee?.LastName[0] : ""}</span>
                </div> */}
                <EmployeeAvatar empId={employee?.EmpID} size='sm' />
            </a>

            <div
                className='dropdown-menu dropdown-menu-end navbar-dropdown-menu navbar-dropdown-menu-borderless navbar-dropdown-account'
                aria-labelledby='accountHeaderBarDropdown'
                style={{ width: '22rem' }}
            >
                <div className='dropdown-item-text'>
                    <div className='d-flex align-items-center'>
                        <EmployeeAvatar empId={employee?.EmpID} size='sm' />
                        <div className='flex-grow-1 ms-3'>
                            <h5 className='mb-0'>
                                {employee ? `${employee.FirstName} ${employee.LastName}` : ''}
                            </h5>
                            <p className='card-text text-body'>{employee?.title}</p>
                        </div>
                    </div>
                </div>

                <div className='dropdown-divider' />

                {/* <!-- Dropdown --> */}
                {/* <div className='dropdown'>
                    <a
                        className='navbar-dropdown-submenu-item dropdown-item dropdown-toggle'
                        id='navSubmenuPagesAccountDropdown1'
                        data-bs-toggle='dropdown'
                        aria-expanded='false'
                    >
                        Set status
                    </a>

                    <div
                        className='dropdown-menu dropdown-menu-end navbar-dropdown-menu navbar-dropdown-menu-borderless navbar-dropdown-sub-menu'
                        aria-labelledby='navSubmenuPagesAccountDropdown1'
                    >
                        <a className='dropdown-item' href='#'>
                            <span className='legend-indicator bg-success me-1'></span> Available
                        </a>
                        <a className='dropdown-item' href='#'>
                            <span className='legend-indicator bg-danger me-1'></span> Busy
                        </a>
                        <a className='dropdown-item' href='#'>
                            <span className='legend-indicator bg-warning me-1'></span> Away
                        </a>
                        <div className='dropdown-divider'></div>
                        <a className='dropdown-item' href='#'>
                            {' '}
                            Reset status
                        </a>
                    </div>
                </div> */}
                {/* <!-- End Dropdown --> */}

                <a
                    className='dropdown-item'
                    href='https://myaccount.microsoft.com/'
                    target='_blank'
                    rel='noreferrer'
                >
                    Profile & account
                </a>

                <a
                    className='dropdown-item'
                    href='https://forms.office.com/r/tEmawEmt4b'
                    target='_blank'
                    rel='noreferrer'
                >
                    Idea Vault
                </a>
                {/* <a className='dropdown-item' href='#'>
                    Settings
                </a> */}
                <div className='dropdown-divider'></div>

                <a
                    className='dropdown-item'
                    href='https://access.paylocity.com/'
                    target='_blank'
                    rel='noreferrer'
                >
                    <div className='d-flex align-items-center'>
                        <div className='flex-shrink-0'>
                            <div className='avatar avatar-sm avatar-dark avatar-circle'>
                                <img
                                    className='avatar-img'
                                    src='/images/paylocity-logo.ico'
                                    alt='ADP Logo'
                                />
                            </div>
                        </div>
                        <div className='flex-grow-1 ms-2'>
                            <h5 className='mb-0'>Paylocity</h5>
                            <span className='card-text'>access.paylocity.com</span>
                        </div>
                        <div className='me-2' style={{ marginLeft: 'auto' }}>
                            <span className='fal fa-external-link' />
                        </div>
                    </div>
                </a>

                <div className='dropdown-divider'></div>

                <a
                    className='dropdown-item'
                    href='https://workspace.ncscredit.com/'
                    target='_blank'
                    rel='noreferrer'
                >
                    <div className='d-flex align-items-center'>
                        <div className='flex-shrink-0'>
                            <div className='avatar avatar-sm avatar-dark avatar-circle'>
                                <img
                                    className='avatar-img'
                                    src='/images/citrix-logo.png'
                                    alt='Jostle Logo'
                                />
                            </div>
                        </div>
                        <div className='flex-grow-1 ms-2'>
                            <h5 className='mb-0'>Citrix</h5>
                            <span className='card-text'>workspace.ncscredit.com</span>
                        </div>
                        <div className='me-2' style={{ marginLeft: 'auto' }}>
                            <span className='fal fa-external-link' />
                        </div>
                    </div>
                </a>

                <div className='dropdown-divider' />

                <div className='dropdown-item' onClick={ClearStorageAndReload}>
                    <div className='d-flex align-items-center'>
                        <div className='flex-shrink-0'>
                            <div className='avatar avatar-sm avatar-dark avatar-circle'>
                                <span className='avatar-img d-flex align-items-center justify-content-center'>
                                    <span className='far fa-refresh fa-xl' />
                                </span>
                            </div>
                        </div>
                        <div className='flex-grow-1 ms-2'>
                            <h5 className='mb-0'>Clear Storage and Refresh</h5>
                            <span className='card-text'>Build #{BuildVersion}</span>
                        </div>
                        {/* <div className='me-2' style={{ marginLeft: 'auto' }}>
                            <span className='fal fa-external-link' />
                        </div> */}
                    </div>
                </div>

                <div className='dropdown-divider' />

                {employee?.CNTEMAIL !== employee?.real_email &&
                    employee?.real_email?.length > 0 && (
                        <a
                            className={'dropdown-item'}
                            href={'#'}
                            onClick={() => {
                                auth.getEmployee(employee.real_email)
                            }}
                        >
                            Change Back
                        </a>
                    )}
                {/* <a
                    className={'dropdown-item'}
                    href={'#'}
                    onClick={() => {
                        throw new Error('Testing error boundary')
                    }}
                >
                    Test Error
                </a> */}
                {/* <a
                    className={'dropdown-item d-flex justify-content-between'}
                    href={'#'}
                    onClick={ClearStorageAndReload}
                >
                    <div>Clear Storage and Refresh</div>
                    <div className='text-muted fw-sm'>Build #{BuildVersion}</div>
                </a> */}
                <a className={'dropdown-item'} href={'#'} onClick={auth.signOut}>
                    Sign Out
                </a>
            </div>
        </div>
    )
}

HeaderBar.Brand = HeaderBarBrand
HeaderBar.MainContent = HeaderBarMainContent
HeaderBar.SecondaryContent = HeaderBarSecondaryContent
HeaderBar.Avatar = HeaderBarAvatar
HeaderBar.Toggler = HeaderBarToggler
