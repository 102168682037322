import { useContext, useEffect } from 'react'
import FrontHeader from './FrontHeader'
import { PortalContext } from './GlobalState'

export default function Header(props) {
    const { admin, employee } = useContext(PortalContext)
    const { access, Department } = employee
    const manager = admin && admin > 0 && admin <= 10
    const superManager = admin && admin > 0 && admin <= 5
    const nmlAccess = checkAccess(Department, access, manager, 'Liens')
    const uccAccess = checkAccess(Department, access, manager, 'UCC')
    const cmAccess = checkAccess(Department, access, manager, 'UCC')

    const links = [
        manager && {
            title: 'Administrator',
            href: '/administrator',
            icon: 'fal fa-key',
            dropdown: [
                { title: 'Attorney Maintenance', href: '/administrator/attorney_maintenance' },
                { title: 'Employee Maintenance', href: '/administrator/emp_maintenance' },
                { title: 'Legislation Updates', href: '/administrator/legislation_updates' },
                {
                    title: 'Liens',
                    href: '/administrator/liens/fees',
                    submenu: [
                        { title: 'Fees', href: '/administrator/liens/fees' },
                        { title: 'Generic Questions', href: '/administrator/liens/questions' },
                        { title: 'Services', href: '/administrator/liens/services' },
                    ],
                },
                {
                    title: 'LTO Postage Administration',
                    href: '/administrator/lto_postage_administration',
                },
                {
                    title: 'Online Services',
                    href: '/administrator/ols_admin/faqs',
                    submenu: [
                        { title: 'FAQs', href: '/administrator/ols_admin/faqs' },
                        { title: 'Glossary', href: '/administrator/ols_admin/glossary' },
                        { title: 'Links', href: '/administrator/ols_admin/links' },
                        { title: 'OLS Alerts', href: '/administrator/ols_admin/ols_alerts' },
                        { title: 'On Demand', href: '/administrator/ols_admin/on_demand' },
                    ],
                },
                { title: 'State Maintenance', href: '/administrator/state_maintenance' },
            ],
        },
        nmlAccess && {
            title: 'NML',
            href: '/nml',
            icon: 'fal fa-helmet-safety',
            dropdown: [
                { title: 'Attorney Follow Up', href: '/nml/attorney' },
                manager && {
                    title: 'Maintenance',
                    href: '/nml/maintenance/assignments',
                    submenu: [
                        { title: 'Assignments', href: '/nml/maintenance/assignments' },
                        { title: 'Email Templates', href: '/nml/maintenance/email_templates' },
                        superManager && { title: 'Research', href: '/nml/maintenance/research' },
                        superManager && {
                            title: 'Docuware Doc Types',
                            href: '/nml/maintenance/docuware_doc_types',
                        },
                        {
                            title: 'Reviewers By State',
                            href: '/nml/maintenance/reviewers_by_state',
                        },
                        { title: 'State Maintenance', href: '/nml/maintenance/state_maintenance' },
                        {
                            title: 'Task Maintenance',
                            href: '/nml/maintenance/task',
                        },
                        superManager && {
                            title: 'Template Editor',
                            href: '/nml/maintenance/template_editor',
                        },
                        superManager && {
                            title: 'Print Queue',
                            href: '/nml/tracker/printing/queue/',
                        },
                    ],
                },
                manager && {
                    title: 'Reports',
                    href: '/nml/report/assignments',
                    submenu: [
                        { title: 'Assignments', href: '/nml/report/assignments' },
                        { title: 'LTO Notices Per Day', href: '/nml/report/lto_notices_per_day' },
                        { title: 'Notices Per Day', href: '/nml/report/notices_per_day' },
                        { title: 'Past Reviews', href: '/nml/report/past_reviews' },
                        { title: 'Queue', href: '/nml/report/queue' },
                    ],
                },
                { title: 'Request', href: '/nml/tracker/request/form' },
                {
                    title: 'Tracker',
                    href: '/nml/tracker/dashboard',
                    submenu: [
                        { title: 'Tracker', href: '/nml/tracker/dashboard' },
                        { title: 'Printing', href: '/nml/tracker/printing' },
                        {
                            title: 'Accounting Export',
                            href: '/nml/tracker/accounting-export',
                        },
                    ],
                },
            ],
        },
        uccAccess && {
            title: 'UCC',
            href: '/ucc',
            icon: 'fal fa-lock-alt',
            dropdown: [
                { title: 'Email Templates', href: '/ucc/email_templates' },
                {
                    title: 'Reports',
                    href: '/ucc/report/errors',
                    submenu: [
                        { title: 'Errors', href: '/ucc/report/errors' },
                        { title: 'Log', href: '/ucc/report/log' },
                        { title: 'Monitoring', href: '/ucc/report/monitoring' },
                        { title: 'Notification Letters', href: '/ucc/report/notes_report' },
                    ],
                },
                { title: 'Settings', href: '/ucc/settings' },
                { title: 'UFile', href: '/ucc/' },
                { title: 'Wk Search', href: '/ucc/search' },
                { title: 'Workflow', href: '/ucc/workflow' },
            ],
        },
        {
            title: 'Client Maintenance',
            href: '/client_maintenance',
            icon: 'fal fa-address-card',
        },
        {
            title: 'Tools',
            href: '/tools',
            icon: 'fal fa-wrench',
            dropdown: [
                { title: 'Bankruptcy Monitoring', href: '/tools/bankruptcy' },
                { title: 'Import Reports', href: '/tools/imports' },
                { title: 'Paylocity', href: 'https://access.paylocity.com/' },
                { title: 'Phone List', href: '/tools/phone_list' },
                { title: 'URL Shortener', href: '/tools/url_shortener' },
                {
                    title: 'CM Change Request',
                    href: 'http://ncs.credit/EA378-C93EB',
                },
                {
                    title: 'Service Agreement',
                    href: 'https://www.ncscredit.com/ncs-credit-service-agreement-form-1/',
                },
            ],
        },
    ]

    return <FrontHeader links={links} />
}

function checkAccess(department, access, manager, accessTo) {
    if (
        accessTo === department ||
        access?.find((r) => r.value === accessTo) ||
        manager ||
        department === 'IT'
    )
        return true

    return false
}
