import classnames from 'classnames'
import React, { useContext } from 'react'

const StepsContext = React.createContext({})

export default function Steps({
    children,
    horizontal,
    centered, // Only works while horizontal
    inline,
    endTrail,
    dashed,
    timeline,
    iconSize = 'md',
    className,
    style,
}) {
    const dateDividersRendered = []
    const latestRelativeDateOptions = []

    return (
        <StepsContext.Provider
            value={{
                dateDividersRendered,
                latestRelativeDateOptions,
            }}
        >
            <ul
                className={classnames('step', `step-icon-${iconSize}`, className, {
                    'step-sm': horizontal,
                    'step-centered': centered,
                    'step-inline': inline,
                    'step-border-last-0': !endTrail,
                    'step-dashed': dashed,
                    'step-timeline-sm': timeline,
                })}
                style={style}
            >
                {children}
            </ul>
        </StepsContext.Provider>
    )
}

function StepsItem({ className, children, selected, onClick = () => {}, ...rest }) {
    return (
        <li className={classnames('step-item', className)} onClick={onClick} {...rest}>
            <div
                className={classnames('step-content-wrapper', {
                    'step-selected': selected,
                })}
            >
                {children}
            </div>
        </li>
    )
}

const StepsIcon = React.forwardRef(
    ({ children, variant = 'primary', soft, size = 'md', square, style, icon, className }, ref) => {
        const noChildren = false

        return (
            <span
                ref={ref}
                className={classnames('step-icon', `step-icon-${size}`, className, {
                    [`step-icon-${variant}`]: !soft,
                    [`step-icon-soft-${variant}`]: soft,
                    'step-icon-pseudo': noChildren,
                })}
                style={{
                    borderRadius: square ? '25%' : null,
                    ...style,
                }}
            >
                {icon && (
                    <i
                        className={classnames(icon, {
                            'me-2': !!children,
                        })}
                    />
                )}
                {children}
            </span>
        )
    }
)

function StepsAvatar({ src }) {
    return (
        <span className={classnames('step-avatar', {})}>
            <img className='step-avatar-img' src={src} />
        </span>
    )
}

function StepsContent({ children }) {
    return <div className='step-content'>{children}</div>
}

function StepsTitle({ as: Component = 'h4', children, className }) {
    return <Component className={classnames(className, 'card-header-title')}>{children}</Component>
}

// TODO: Make this look right
function StepsSubtitle({ as: Component = 'span', children, className }) {
    return <Component className={classnames(className, 'text-muted')}>{children}</Component>
}

function StepsText({ children, className }) {
    return <div className={classnames(className, 'step-text')}>{children}</div>
}

// Can only be used with vertical steps
function StepsDivider({ children, hideTrail, className, ...rest }) {
    return (
        <li
            {...rest}
            className={classnames('step-item', className, {
                'hide-trail': hideTrail,
            })}
        >
            <div className='step-content-wrapper'>
                <small className='step-divider'>{children}</small>
            </div>
        </li>
    )
}

function StepsDateDivider({ date, ...rest }) {
    const { dateDividersRendered, latestRelativeDateOptions } = useContext(StepsContext)

    // If date is not a valid instance of Date, create a Date
    const { label, relativeDateOptions } = getRelativeDateOptions(date)

    // Only return something if this specific label has not appeared yet
    if (dateDividersRendered?.at(-1) != label) {
        dateDividersRendered?.push(label)
        latestRelativeDateOptions?.push(relativeDateOptions)
        return <StepsDivider {...rest}>{label}</StepsDivider>
    }
    return null
}

function getRelativeDateOptions(date) {
    if (typeof date?.getMonth !== 'function') {
        date = new Date(date)
        date = Date.UTC(date.getFullYear(), date?.getMonth(), date.getDate())
    }

    let now = new Date()
    now = Date.UTC(now.getFullYear(), now.getMonth(), now.getDate())

    const differenceInDays = Math.floor((now - date) / (1000 * 3600 * 24))
    let label
    let relativeDateOptions

    if (differenceInDays == 0) {
        label = 'today'
        relativeDateOptions = {
            hour: 'numeric',
            minute: 'numeric',
        }
    } else if (differenceInDays == 1) {
        label = 'yesterday'
        relativeDateOptions = {
            hour: 'numeric',
            minute: 'numeric',
            weekday: 'short',
        }
    } else if (differenceInDays < 7) {
        label = `${differenceInDays} days ago`
        relativeDateOptions = {
            month: 'short',
            day: 'numeric',
        }
    } else if (differenceInDays < 31) {
        const numWeeks = Math.floor(differenceInDays / 7)
        label = `${numWeeks} ${numWeeks == 1 ? 'week' : 'weeks'} ago`
        relativeDateOptions = {
            month: 'short',
            day: 'numeric',
        }
    } else if (differenceInDays < 365) {
        const numMonths = Math.floor(differenceInDays / 31)
        label = `${numMonths} ${numMonths == 1 ? 'month' : 'months'} ago`
        relativeDateOptions = {
            month: 'short',
            day: 'numeric',
        }
    } else {
        const numYears = Math.floor(differenceInDays / 365)
        label = `${numYears} ${numYears == 1 ? 'year' : 'years'} ago`
        relativeDateOptions = {
            year: 'numeric',
            month: 'short',
            day: 'numeric',
        }
    }

    return { label, relativeDateOptions }
}

function StepsRelativeDate({ date }) {
    const { relativeDateOptions } = getRelativeDateOptions(date)
    // If date is not a valid instance of Date, create a Date
    if (typeof date?.getMonth !== 'function') {
        date = new Date(date)
    }
    return <>{new Intl.DateTimeFormat('En-US', relativeDateOptions)?.format(date)}</>
}

Steps.Item = StepsItem
Steps.Icon = StepsIcon
Steps.Avatar = StepsAvatar
Steps.Content = StepsContent
Steps.Title = StepsTitle
Steps.Subtitle = StepsSubtitle
Steps.Text = StepsText
Steps.Divider = StepsDivider
Steps.DateDivider = StepsDateDivider
Steps.RelativeDate = StepsRelativeDate
