import { NcsIcon, Tooltip } from '@/components/utils'
import classnames from 'classnames'
import { Badge, Button, Card, Dropdown, DropdownButton, Form, ListGroup } from 'front'
import { Modal } from 'react-bootstrap'
import React, { useContext, useState } from 'react'
import { useTrackerDropdowns } from 'tracker/api'
import { associateLabel } from 'tracker/file/project/associates/utils.js/associatesUtils'
import { ProjectContext } from 'tracker/Context/ProjectContext'
import { useRouter } from 'next/router'
import { postagesCantAcceptInvalidAddresses } from '../hooks/usePacketRecipients'
import classNames from 'classnames'
import Link from 'next/link'

export function Recipients({
    packet,
    recipients,
    updateAllRecipientsPostage,
    updateRecipientPostage,
    selectRecipient,
    canEdit = true,
    onMinimize,
    USOnlyPostageCodes,
    onMergeDocuments,
}) {
    const { data: dropdowns } = useTrackerDropdowns()
    const postageOptions = dropdowns?.['docgen.postage'] ?? []
    const USTerritories = ['PR', 'VI', 'AE', 'AA', 'AP', 'AS', 'FM', 'GU', 'MH', 'MP']
    const usAddressExists = recipients?.some(
        (recipient) =>
            dropdowns?.['state']?.find(({ code }) => code === recipient?.state)?.country ===
                'USA' || USTerritories.includes(recipient?.state)
    )
    console.log('🚀 ~ usAddressExists:', usAddressExists)

    const parents = recipients
        ?.filter(
            (assoc) =>
                !assoc?.parent_associate_id ||
                (assoc?.parent_associate_id &&
                    !recipients?.find(({ _id }) => assoc?.parent_associate_id == _id))
        )
        ?.map((assoc) => ({
            ...assoc,
            children: recipients?.filter(
                (a) => !a?.is_deleted && a.parent_associate_id == assoc._id
            ),
        }))
        ?.sort((a, b) => {
            if (a.type_id === b.type_id) {
                if (a.is_primary && !b.is_primary) {
                    return -1
                } else if (!a.is_primary && b.is_primary) {
                    return 1
                }
            }
            return a.type_id - b.type_id
        })

    const allTrackingNumbers = recipients
        ?.filter((recipient) => recipient?.tracking_number)
        ?.map((recipient) => recipient?.tracking_number)

    const [showDoNotSendWarning, setShowDoNotSendWarning] = useState(false)

    const handleMergeDocuments = () => {
        const hasDoNotSendRecipient = recipients.some((r) => r.selected && r._dnn)
        if (hasDoNotSendRecipient) {
            setShowDoNotSendWarning(true)
        } else {
            onMergeDocuments()
        }
    }

    return (
        <Card>
            <Card.Header className='d-flex justify-content-between align-items-center'>
                <Card.Title as='h4'>Recipients</Card.Title>
                <div>
                    {canEdit && (
                        <DropdownButton
                            ghost
                            variant='secondary'
                            title='Set all postages'
                            disabled={!canEdit}
                        >
                            {postageOptions
                                ?.filter(
                                    (postageType) =>
                                        !(
                                            USOnlyPostageCodes.includes(postageType?.code) &&
                                            !usAddressExists
                                        )
                                )
                                ?.map((postageType, i) => {
                                    return (
                                        <Dropdown.Item
                                            key={i}
                                            onClick={() =>
                                                updateAllRecipientsPostage(postageType?.code)
                                            }
                                        >
                                            <PostageOptionLabel value={postageType?.code} />
                                            {postageType?.code === 'CNRR' && (
                                                <Badge bg='secondary' soft className='float-end'>
                                                    Batch 10
                                                </Badge>
                                            )}
                                        </Dropdown.Item>
                                    )
                                })}
                        </DropdownButton>
                    )}
                    {allTrackingNumbers.length > 0 && (
                        <div className='fw-bold'>
                            <Link
                                href={`https://tools.usps.com/go/TrackConfirmAction?tLabels=${allTrackingNumbers.join(
                                    ','
                                )}`}
                                target='_blank'
                            >
                                View All Tracking
                            </Link>
                        </div>
                    )}
                </div>
            </Card.Header>

            {recipients.length > 0 ? (
                <ListGroup noBorders>
                    {packet?.noRecipientsSelected && (
                        <ListGroup.Item className='bg-soft-info text-info rounded-0'>
                            <i className='fas fa-circle-exclamation me-2' />
                            Select at least one recipient
                        </ListGroup.Item>
                    )}
                    {packet?.invalidRecipientsSelected && (
                        <ListGroup.Item className='bg-soft-danger text-danger rounded-0'>
                            <i className='fas fa-circle-exclamation me-2' />
                            Fix or unselect invalid recipients
                        </ListGroup.Item>
                    )}
                    {parents?.map((recipient, i) => {
                        return (
                            <React.Fragment key={recipient?._id}>
                                <RecipientListItem
                                    canEdit={canEdit}
                                    key={recipient?._id}
                                    recipient={recipient}
                                    updateRecipientPostage={updateRecipientPostage}
                                    selectRecipient={selectRecipient}
                                    onMinimize={onMinimize}
                                    USOnlyPostageCodes={USOnlyPostageCodes}
                                    usAddressExists={usAddressExists}
                                />
                                {recipient?.children?.map((child) => (
                                    <RecipientListItem
                                        canEdit={canEdit}
                                        key={child?._id}
                                        recipient={child}
                                        updateRecipientPostage={updateRecipientPostage}
                                        selectRecipient={selectRecipient}
                                        onMinimize={onMinimize}
                                        USOnlyPostageCodes={USOnlyPostageCodes}
                                        usAddressExists={usAddressExists}
                                    />
                                ))}
                            </React.Fragment>
                        )
                    })}
                </ListGroup>
            ) : (
                <Card.Body>No recipients in file</Card.Body>
            )}
        </Card>
    )
}

function RecipientListItem({
    recipient,
    updateRecipientPostage,
    selectRecipient,
    canEdit,
    onMinimize,
    USOnlyPostageCodes,
    usAddressExists,
}) {
    const {
        _id,
        name,
        _type_name,
        selected,
        is_debtor,
        is_primary,
        postage_type,
        address1,
        address2,
        city,
        state,
        zip,
        isAddressValid = null,
        addressStatusMessage = null,
        parent_associate_id,
        tracking_number = null,
        isAddressFilled,
        _dnn,
    } = recipient
    const { data: dropdowns } = useTrackerDropdowns()
    const { fileInfo, goToAssociatesPage } = useContext(ProjectContext)
    const router = useRouter()
    const [isHover, setIsHover] = useState(false)
    const addressInvalid =
        !isAddressValid &&
        (postagesCantAcceptInvalidAddresses.includes(postage_type) || !isAddressFilled)
    const fullAddress = [address1, address2, city, state, zip]?.filter((item) => !!item)?.join(', ')
    const postageLabel = dropdowns?.['docgen.postage']?.find((p) => p?.code == postage_type)?.label

    return (
        <ListGroup.Item
            action={canEdit}
            className={classnames('d-flex justify-content-start align-items-center', {
                'cursor-pointer': canEdit,
                'cursor-default': !canEdit,
                'bg-soft-danger': (addressInvalid || _dnn) && selected,
                'py-2': parent_associate_id,
            })}
            onClick={() => {
                if (canEdit) selectRecipient(_id, !selected)
            }}
            onMouseEnter={() => setIsHover(true)}
            onMouseLeave={() => setIsHover(false)}
        >
            {parent_associate_id && (
                <i
                    className='far fa-chevron-right d-block me-2 text-body'
                    style={{ transform: 'rotate(135deg)' }}
                />
            )}
            <Form.Check
                type='checkbox'
                checked={!!selected}
                disable={!canEdit}
                readOnly
                className={classnames({
                    'form-check-danger': addressInvalid || _dnn,
                })}
            />
            <div
                className={classnames('ms-3 d-flex align-items-center me-3 position-relative', {
                    'fs-smaller': parent_associate_id,
                })}
            >
                <div>
                    <div
                        className={classnames('fw-bold', {
                            'text-body': !selected,
                            'text-danger': (addressInvalid || _dnn) && selected,
                        })}
                    >
                        {associateLabel(recipient)}
                        {is_primary && <i className='fas fa-badge-check ms-1 text-muted' />}
                        {is_debtor && (
                            <Badge bg='secondary' soft className='ms-2'>
                                Debtor
                            </Badge>
                        )}
                    </div>
                    <div className='text-body'>{name}</div>
                    <div
                        className={classnames({
                            'text-body': !(addressInvalid && selected),
                            'text-danger': addressInvalid && selected,
                        })}
                    >
                        {fullAddress.length > 0 ? fullAddress : 'No address'}
                    </div>
                </div>
            </div>
            <div className='ms-auto d-flex flex-column justify-content-end'>
                {addressInvalid && (
                    <div className='text-end'>
                        <Badge bg='danger' soft className='ms-1 fs-5 fw-light me-2'>
                            <i className='fas fa-circle-exclamation me-2' />
                            {addressStatusMessage}
                        </Badge>
                    </div>
                )}
                {_dnn && (
                    <div className='text-end'>
                        <Badge bg='danger' soft className='ms-1 fs-5 fw-light me-2'>
                            <i className='fas fa-circle-exclamation me-2' />
                            Do not send list
                        </Badge>
                    </div>
                )}
                {selected && !tracking_number && (
                    <>
                        {canEdit ? (
                            <DropdownButton
                                variant='white'
                                title={<PostageOptionLabel value={postage_type} />}
                                disabled={!canEdit}
                                onClick={(e) => {
                                    e.stopPropagation()
                                }}
                                className='d-inline-block'
                            >
                                {dropdowns?.['docgen.postage']
                                    ?.filter(
                                        (postageType) =>
                                            !(
                                                USOnlyPostageCodes.includes(postageType?.code) &&
                                                !usAddressExists
                                            )
                                    )
                                    ?.sort()
                                    ?.map((postageType, i) => {
                                        return (
                                            <Dropdown.Item
                                                key={i}
                                                onClick={() => {
                                                    updateRecipientPostage(_id, postageType?.code)
                                                }}
                                            >
                                                <PostageOptionLabel value={postageType?.code} />
                                                {postageType?.code === 'CNRR' && (
                                                    <Badge
                                                        bg='secondary'
                                                        soft
                                                        className='float-end'
                                                    >
                                                        Batch 10
                                                    </Badge>
                                                )}
                                            </Dropdown.Item>
                                        )
                                    })}
                            </DropdownButton>
                        ) : (
                            <div className='text-body'>{postageLabel}</div>
                        )}
                    </>
                )}
                {tracking_number && (
                    <div className='d-flex flex-column justify-content-end'>
                        <div className='text-end text-body'>{postageLabel}</div>
                        <div className='fw-bold text-end'>
                            <Link
                                href={`https://tools.usps.com/go/TrackConfirmAction?tLabels=${tracking_number}`}
                            >
                                View Tracking
                            </Link>
                        </div>
                    </div>
                )}
                {canEdit && (
                    <Tooltip content='Edit associate' placement='top'>
                        <Button
                            variant='white'
                            circle
                            onClick={(e) => {
                                e.stopPropagation()
                                onMinimize()
                                router.push({
                                    pathname: '/nml/tracker/file/associates',
                                    query: {
                                        project_id: fileInfo?.project_id,
                                        associate_id: _id,
                                    },
                                })
                            }}
                            className={classnames('ms-2 d-inline-block position-absolute end-0', {
                                'opacity-0': !isHover,
                            })}
                            size='sm'
                            style={{ marginRight: -20, top: '25%' }}
                        >
                            <i className='fal fa-pen-to-square fa-sm' />
                        </Button>
                    </Tooltip>
                )}
            </div>
        </ListGroup.Item>
    )
}

const PostageOptionLabel = ({ value }) => {
    const { data: dropdowns } = useTrackerDropdowns()
    const postageType = dropdowns?.['docgen.postage']?.find(
        (p) => p.code === value || p.legacy_value == value
    )

    return (
        <span>
            <NcsIcon dept='nml' category='postage' value={value} className='me-2 fa-fw' />
            {postageType?.label}
        </span>
    )
}

function DoNotSendWarningModal({ show, onHide, onConfirm }) {
    return (
        <Modal show={show} onHide={onHide}>
            <Modal.Header closeButton>
                <Modal.Title>Warning: Do Not Send List</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                One or more selected recipients are on the Do Not Send list. Are you sure you want
                to proceed?
            </Modal.Body>
            <Modal.Footer>
                <Button variant='secondary' onClick={onHide}>
                    Cancel
                </Button>
                <Button variant='warning' onClick={onConfirm}>
                    Proceed Anyway
                </Button>
            </Modal.Footer>
        </Modal>
    )
}
