import { PortalContext } from '@/components/GlobalState'
import { useContext } from 'react'
import { Alert } from 'react-bootstrap'
import { OlsChangesAlert } from './project/overview/OlsChangesAlert'
import { Button } from 'front'
import { useImportProject } from 'tracker/api/projectSearch.api'
import useDashboardQueue from 'tracker/hooks/useDashboardQueue'
import Link from 'next/link'
import { useRouter } from 'next/router'

export default function FileAlerts({ fileEdit, fileInfo }) {
    const router = useRouter()
    const { employee } = useContext(PortalContext)
    const { filters } = useDashboardQueue()
    const { mutate: importProject, isLoading: importProjectLoading } = useImportProject({
        project_id: fileInfo?.project_id,
        filters,
        onSuccess: () => {
            router.reload()
        },
    })

    let packetsInReview = []
    let tasksAheadOfPacket = []
    fileInfo?.tasks?.map((task) => {
        const packetsAvailable = task?.packets?.filter((packet) => packet.status != 'DELETED')
        if (task?.current_stage === 'REVIEW') {
            packetsAvailable?.map((packet) => {
                const inTasksAhead = tasksAheadOfPacket.find((r) => r.task.id === task.id)
                const inPacketsAhead = packetsInReview.find((r) => r.task.id === task.id)
                if (
                    packet?.status !== 'PENDING REVIEW' &&
                    packet?.status !== 'PRINT QUEUED' &&
                    task?.is_service &&
                    !inTasksAhead
                ) {
                    tasksAheadOfPacket.push({
                        ...packet,
                        task,
                        message: `${task._task_code_label} is in review but a packet is not currently being reviewed`,
                    })
                }

                if (
                    packet?.status === 'PENDING REVIEW' &&
                    task?.current_employee_id != employee?.EmpID &&
                    !inPacketsAhead
                ) {
                    packetsInReview.push({ ...packet, task })
                }
            })
        } else if (
            task?.current_stage === 'SERVING' &&
            task?.is_service &&
            task?.status !== 'COMPLETED'
        ) {
            packetsAvailable?.map((packet) => {
                const inTasksAhead = tasksAheadOfPacket.find((r) => r.task.id === task.id)
                if (
                    packet?.status !== 'PRINT QUEUED' &&
                    packet?.status !== 'COMPLETED' &&
                    !inTasksAhead
                ) {
                    tasksAheadOfPacket.push({
                        ...packet,
                        task,
                        message: `${task._task_code_label} is in Serving/Recording stage but a packet is not currently being queued to print`,
                    })
                }
            })
        }
    })
    return (
        <div className='px-3'>
            {fileEdit && fileInfo?.project?._workflow === null && (
                <Alert
                    variant='soft-primary'
                    className='d-flex justify-content-between align-items-center'
                >
                    <div>
                        <i className='far fa-file-import me-3' />
                        This project is not imported yet, would you like to import into Portal?
                    </div>
                    <Button
                        variant='primary'
                        loading={importProjectLoading}
                        disabled={importProjectLoading}
                        onClick={importProject}
                    >
                        Yes
                    </Button>
                </Alert>
            )}
            {/* {fileEdit && fileInfo?.project?.is_in_collection && (
                <Alert
                    variant='soft-coll'
                    className='d-flex justify-content-between align-items-center'
                >
                    <div>
                        <i className='far fa-circle-exclamation me-3' />
                        This project is in collections, any changes should be communicated to the
                        collections department.
                    </div>
                </Alert>
            )} */}
            {fileInfo?.incoming_changes?.version?.id && <OlsChangesAlert />}
            {fileInfo?.warnings?.debtor_missing && (
                <Alert
                    variant='soft-danger'
                    className='d-flex justify-content-between align-items-center'
                >
                    <div>
                        <i className='far fa-circle-exclamation me-3' />
                        There is no debtor selected for this project
                    </div>
                    <Link href={`/nml/tracker/file/associates?project_id=${fileInfo?.project_id}`}>
                        <Button variant='link' className='text-danger fw-bold' soft size='sm'>
                            Go to associates <i className='fas fa-arrow-right ms-2' />
                        </Button>
                    </Link>
                </Alert>
            )}
            {fileInfo?.warnings?.contact_missing && (
                <Alert
                    variant='soft-danger'
                    className='d-flex justify-content-between align-items-center'
                >
                    <div>
                        <i className='far fa-circle-exclamation me-3' />
                        There is no contact selected for this file. Please select one.
                    </div>
                </Alert>
            )}
            {fileInfo?.warnings?.contact_error && (
                <Alert
                    variant='soft-danger'
                    className='d-flex justify-content-between align-items-center'
                >
                    <div>
                        <i className='far fa-circle-exclamation me-3' />
                        There was an error with the contact selected for this file. Please review
                        the contact and make any necessary changes.
                    </div>
                </Alert>
            )}
            {fileInfo?.warnings?.pending_docgen_packet && (
                <Alert variant='soft-danger'>
                    <i className='far fa-circle-exclamation me-3' />A document packet is pending.
                    Any project changes will need to be manually made to this packet as well.
                </Alert>
            )}
            {fileInfo?.warnings?.date_engine_error && (
                <Alert variant='sosft-danger'>
                    <i className='far fa-circle-exclamation me-3' />
                    There was an error calculating dates. Please review the dates and make any
                    necessary changes
                </Alert>
            )}
            {tasksAheadOfPacket.length > 0 && (
                <Alert variant='soft-danger'>
                    <div className='d-flex align-items-center justify-content-between'>
                        <div className='d-flex align-items-center'>
                            <div>
                                <i className='far fa-circle-exclamation me-3 mt-1' />
                            </div>
                            <div className='d-flex flex-column'>
                                {tasksAheadOfPacket?.map((task, i) => {
                                    return <div key={`${task?._id}-${i}`}>{task?.message}</div>
                                })}
                            </div>
                        </div>
                    </div>
                </Alert>
            )}
            {packetsInReview.length > 0 && (
                <Alert variant='soft-primary'>
                    <div className='d-flex align-items-center justify-content-between'>
                        <div className='d-flex align-items-center'>
                            <div>
                                <i className='far fa-circle-exclamation me-3 mt-1' />
                            </div>
                            <div className='d-flex flex-column'>
                                {packetsInReview?.map((packet, i) => {
                                    return (
                                        <div key={`${packet?._id}-${i}`}>
                                            {packet?.task?._task_code_label} - Packet is in review
                                            by {packet?.task?.employee_name}
                                        </div>
                                    )
                                })}
                            </div>
                        </div>
                        <div>Please let them review before making changes</div>
                    </div>
                </Alert>
            )}
        </div>
    )
}
