import { useJsApiLoader } from '@react-google-maps/api'
import classnames from 'classnames'
import { useContext, useEffect, useState } from 'react'
import { PortalContext } from '../GlobalState'
import { GOOGLE_MAPS_CONFIG } from './GoogleMapsUtils'

export default function GoogleMaps(props) {
    const { isLoaded } = useJsApiLoader(GOOGLE_MAPS_CONFIG)

    let {
        // LOCATION: Choose from the following sets of options //
        // Option 1
        fullAddress,

        // Option 2
        streetAddress1,
        streetAddress2,
        city,
        state,
        zip,

        // Option 3
        latitude,
        longitude,

        // Option 4
        placeId,

        // GOOGLE MAPS SETTINGS //
        satellite,
        roadmap,
        allowFullScreen,
        frameBorder,
        width = '100%',
        height = '500px',

        // BASIC HTML ATTRIBUTES //
        className,
        id,
        style,
    } = props

    const { theme } = useContext(PortalContext)
    const [satelliteEnabled, setSatelliteEnabled] = useState(satellite)
    const [reloadOver, setReloadOver] = useState(false)
    const [shouldUseLightTheme, setShouldUseLightTheme] = useState(
        satelliteEnabled || theme == 'light'
    )

    useEffect(() => determineWhichTheme(), [theme])

    const determineWhichTheme = () => setShouldUseLightTheme(satelliteEnabled || theme == 'light')

    // Build query
    let query = createGoogleMapsQuery(props)

    if (!isLoaded) return null

    return (
        <div style={{ position: 'relative', height: style?.height ?? height }}>
            {theme == 'dark' && (
                <div
                    onClick={() => {
                        setSatelliteEnabled(!satelliteEnabled)
                    }}
                    className={classnames('google-maps-satellite-toggle', {
                        'dark-border': !satelliteEnabled,
                    })}
                />
            )}
            <iframe
                className={classnames('google-maps-embed', className)}
                id={id}
                style={{
                    width: width,
                    height: height,
                    filter: `invert(${shouldUseLightTheme ? 0 : 97}%)`,
                    ...style,
                }}
                src={`https://www.google.com/maps/embed/v1/place?q=${query}&key=${
                    GOOGLE_MAPS_CONFIG.googleMapsApiKey
                }&maptype=${satelliteEnabled ? 'satellite' : 'roadmap'}`}
                frameBorder={frameBorder ? frameBorder : '0'}
                onLoad={determineWhichTheme}
            ></iframe>
        </div>
    )
}

function createGoogleMapsQuery(paramObj) {
    let {
        placeId,
        latitude,
        longitude,
        fullAddress,
        streetAddress1,
        streetAddress2,
        city,
        state,
        zip,
    } = paramObj

    let query = null
    if (placeId) {
        query = `place_id:${placeId}`
    } else if (latitude && longitude) {
        query = latitude + ',' + longitude
    } else {
        if (fullAddress) {
            query = fullAddress
        } else {
            query = ''
            query += streetAddress1 ? streetAddress1 + '' : ''
            // address += streetAddress2 ? streetAddress2 + "," : ",";
            query += city ? city + ',' : ''
            query += state ? state + '+' : ''
            query += zip ? zip : ''
        }

        query = query.split('#').join('')
        query = query.split(' ').join('+')
    }

    return encodeURIComponent(query)
}

function GoogleMapsUrl({ as: Component, className, style, children, ...addressProps }) {
    let query = createGoogleMapsQuery(addressProps)
    let url = `https://www.google.com/maps/?q=${query}`

    return (
        <Component className={className} style={style} href={url} target='_blank'>
            {children}
        </Component>
    )
}
GoogleMapsUrl.defaultProps = {
    as: 'a',
}

GoogleMaps.Url = GoogleMapsUrl
