import { EmployeeAvatarPopup, LoadingSpinner } from 'components/utils'
import { Badge, Button } from 'front'
import { useCopyToClipboard, useModalState } from 'hooks'
import { useRouter } from 'next/router'
import { useContext, useEffect, useState } from 'react'
import { ToggleButton, ToggleButtonGroup } from 'react-bootstrap'
import { useUpdateProject } from 'tracker/api'
import { ProjectContext } from 'tracker/Context/ProjectContext'
import NavbarInfo from 'tracker/Utils/KeyboardShortcuts/NavbarInfo'
import ProjectLookupModal from 'tracker/Utils/KeyboardShortcuts/ProjectLookupModal'
import { nldUrl } from 'tracker/Utils/OpenNLD'
import Sidebar from '../../../front/sidebar/Sidebar'
import { Tooltip } from '../../../utils'
import NavSearch from '../Utils/KeyboardShortcuts/NavSearch'
import ResearchLinksModal from './ResearchLinksModal'
import { TrackerLinksContext } from './TrackerLinksContext'

export default function Nav(props) {
    const { fileInfo, openProjectPopoutWindow } = useContext(ProjectContext)

    const router = useRouter()
    let lno = fileInfo?.project?.ncs_lno
    if (lno?.length === 0) {
        lno = 'NEW: ' + fileInfo?.project_id
    }

    const project_id = router.query?.['project_id']
    const { links } = useContext(TrackerLinksContext)
    const researchLinksModal = useModalState(false)

    return (
        <>
            <Sidebar>
                <div className='d-flex justify-content-between align-items-center pt-3'>
                    {/* <div className='d-flex align-items-start'> */}
                    <span
                        className='fal fa-circle-chevron-left fa-xl mx-1 pointer'
                        onClick={() => {
                            router.push(`/nml/tracker/dashboard`)
                        }}
                    />
                    {/* </div> */}
                    <ProjectId projectId={lno} />
                    <TitleBadges fileInfo={fileInfo} />
                </div>
                <ManagedByNcsToggle project_id={project_id} />
                <NavSearch onClick={props?.searchModal?.show} />
                <Sidebar.Content>
                    <Sidebar.Nav pills>
                        {links.map((link, i) => {
                            if (link?.links?.length > 0) {
                                return (
                                    <span key={i}>
                                        <Sidebar.NavHeader className={i > 0 ? 'mt-4' : ''}>
                                            {link.title}
                                        </Sidebar.NavHeader>
                                        {link?.links?.length > 0 &&
                                            link?.links?.map((subLink, j) => {
                                                const active = router.pathname.startsWith(
                                                    subLink?.href
                                                )

                                                if (String(subLink?.href)?.startsWith('/')) {
                                                    return (
                                                        <Sidebar.NavLink
                                                            active={active}
                                                            href={{
                                                                pathname: subLink?.href,
                                                                query: {
                                                                    project_id:
                                                                        router.query.project_id,
                                                                },
                                                            }}
                                                            icon={subLink?.icon + ' fa-lg'}
                                                            key={j}
                                                            popout={subLink?.popout}
                                                        >
                                                            <div
                                                                className={
                                                                    'ms-2 w-100 d-flex justify-content-between'
                                                                }
                                                            >
                                                                <div>{subLink?.title}</div>
                                                                {subLink?.notification > 0 && (
                                                                    <Badge
                                                                        className='d-block me-1'
                                                                        bg='primary'
                                                                        pill
                                                                    >
                                                                        {subLink?.notification}
                                                                    </Badge>
                                                                )}
                                                            </div>
                                                        </Sidebar.NavLink>
                                                    )
                                                }
                                                return (
                                                    subLink && (
                                                        <Sidebar.NavLink
                                                            key={j}
                                                            icon={subLink?.icon + ' fa-lg'}
                                                            href={subLink?.href}
                                                            onClick={subLink?.onClick}
                                                            popout={subLink?.popout}
                                                        >
                                                            <div className='ms-2 w-100 d-flex justify-content-between'>
                                                                <div
                                                                    className={
                                                                        'w-100 d-flex justify-content-between'
                                                                    }
                                                                >
                                                                    <div>{subLink?.title}</div>
                                                                    {subLink?.notification &&
                                                                        subLink?.notification
                                                                            ?.text !== 0 && (
                                                                            <Badge
                                                                                className='d-block me-1'
                                                                                bg={
                                                                                    subLink
                                                                                        ?.notification
                                                                                        ?.bg
                                                                                }
                                                                                soft={
                                                                                    subLink
                                                                                        ?.notification
                                                                                        ?.soft
                                                                                }
                                                                                pill
                                                                            >
                                                                                {
                                                                                    subLink
                                                                                        ?.notification
                                                                                        ?.text
                                                                                }
                                                                            </Badge>
                                                                        )}
                                                                </div>
                                                            </div>
                                                        </Sidebar.NavLink>
                                                    )
                                                )
                                            })}
                                    </span>
                                )
                            }
                        })}
                    </Sidebar.Nav>
                </Sidebar.Content>

                {fileInfo?.main_contact?.id && (
                    <div className='d-flex flex-column justify-content-center align-items-center pt-3 pb-2 border-top'>
                        <div className='d-flex align-items-center '>
                            <label className='text-muted fs-smaller'>NCS Client Contact</label>
                        </div>
                        <div className='d-flex align-items-center'>
                            <EmployeeAvatarPopup
                                tooltipPlacement='bottom'
                                empId={fileInfo?.main_contact?.id}
                                includeLabel
                                size='xs'
                            />
                        </div>
                    </div>
                )}
                <Sidebar.Footer>
                    <Tooltip content='Open in NLD' trigger={['hover', 'click']}>
                        <div className='position-relative'>
                            <Button
                                icon='fal fa-book-open-cover fa-lg'
                                circle
                                ghost
                                variant='secondary'
                                onClick={() => {
                                    openProjectPopoutWindow(
                                        'nld',
                                        nldUrl(
                                            fileInfo?.project?.project_type,
                                            fileInfo?.project?.state
                                        ),
                                        `NLD ${fileInfo?.project_id} - ${fileInfo?.project?.state} - ${fileInfo?.project?.project_type}`
                                    )
                                }}
                            />
                        </div>
                    </Tooltip>
                    <Tooltip content='Research Links' trigger={['hover', 'click']}>
                        <div className='position-relative'>
                            <Button
                                icon='fal fa-link fa-lg'
                                circle
                                ghost
                                variant='secondary'
                                onClick={researchLinksModal.show}
                            />
                        </div>
                    </Tooltip>
                    <ResearchLinksModal
                        show={researchLinksModal.isOpen}
                        onHide={researchLinksModal.hide}
                    />
                    <NavbarInfo />
                </Sidebar.Footer>
            </Sidebar>
        </>
    )
}

function ProjectId({ projectId }) {
    const { copyToClipboard } = useCopyToClipboard()
    const [showPopup, setShowPopup] = useState(false)
    const [showProjectLookup, setShowProjectLookup] = useState(false)

    const onClick = () => {
        setShowPopup(true)
        copyToClipboard(projectId)
        setTimeout(() => setShowPopup(false), 1000)
    }

    return (
        <>
            <Tooltip content={'Copied!'} trigger={false} show={showPopup} placement={'bottom'}>
                <h4
                    onClick={onClick}
                    className='sidebar-header-title p-0 m-0 pointer'
                    // onDoubleClick={() => {
                    //     setShowProjectLookup(true)
                    // }}
                >
                    {projectId}
                </h4>
            </Tooltip>
            <ProjectLookupModal
                show={showProjectLookup}
                onHide={() => {
                    setShowProjectLookup(false)
                }}
            />
        </>
    )
}

export function TitleBadges({ fileInfo }) {
    return (
        <div>
            {fileInfo?.project?.is_active && !fileInfo?.project?.is_deleted && (
                <Badge className='ms-2' soft bg='success'>
                    Open
                </Badge>
            )}
            {fileInfo && !fileInfo?.project?.is_active && !fileInfo?.project?.is_deleted && (
                <Badge className='ms-2' soft bg='secondary'>
                    Closed
                </Badge>
            )}
            {fileInfo?.project?.is_deleted && (
                <Badge className='ms-2' soft bg='danger'>
                    Deleted
                </Badge>
            )}
            {/* {fileInfo?.version && (
                <Badge className='ms-2' soft statusDot bg='info'>
                    Changes
                </Badge>
            )} */}
        </div>
    )
}

function ManagedByNcsToggle({ project_id }) {
    const { fileInfo, fileEdit, updateProject } = useContext(ProjectContext)
    const { is_managed } = fileEdit?.project ?? {}
    return (
        <>
            <div className='mb-3 mt-3'>
                {/* <div className='text-center'>
                    <small>Managed by</small>
                </div> */}
                <ToggleButtonGroup
                    name='managedByToggle'
                    value={is_managed}
                    type='radio'
                    className='btn-group-segment w-100'
                    size='xs'
                >
                    <ToggleButton
                        value={true}
                        variant=''
                        onClick={() => updateProject('is_managed', true)}
                        className='d-flex justify-content-center align-items-center'
                    >
                        <div className='fw-bold'>Managed</div>
                    </ToggleButton>
                    <ToggleButton
                        value={false}
                        variant=''
                        onClick={() => updateProject('is_managed', false)}
                        className='d-flex justify-content-center align-items-center'
                    >
                        <div className='fw-bold'>
                            <div>Unmanaged</div>
                            <div className='fs-smaller opacity-75' style={{ marginTop: -5 }}>
                                (Express)
                            </div>
                            {/* <i className='fas fa-circle-info ms-2' /> */}
                        </div>
                    </ToggleButton>
                </ToggleButtonGroup>
            </div>
        </>
    )
}

function ManagedByProjectToggle({ project_id }) {
    const { fileInfo } = useContext(ProjectContext)
    useEffect(() => {})
    const { mutate: updateProject, isLoading } = useUpdateProject({ project_id })
    const [isHovered, setIsHovered] = useState(false)
    const handleMouseEnter = () => {
        setIsHovered(true)
    }

    // Function to change hover state to false
    const handleMouseLeave = () => {
        setIsHovered(false)
    }

    return (
        <div
            onMouseEnter={handleMouseEnter}
            onMouseLeave={handleMouseLeave}
            className={'text-center pb-1 pointer ' + (isHovered ? 'text-primary' : '')}
            onClick={() => {
                updateProject({ project: { is_managed: !fileInfo?.project?.is_managed } })
            }}
        >
            {isLoading ? (
                <h4>
                    <LoadingSpinner /> Switching...
                </h4>
            ) : isHovered ? (
                <>Change to {fileInfo?.project?.is_managed ? 'Client' : 'NCS'}</>
            ) : (
                <>Managed By {fileInfo?.project?.is_managed ? 'NCS' : 'Client'}</>
            )}
        </div>
    )
}
