import { Card, Form, ListGroup } from 'front'
import { useState, useMemo } from 'react'
import { toTitleCase } from 'utils'
import { DocumentIcon } from './DocumentIcon'
import Fuse from 'fuse.js'

export default function InitialTemplatePicker({ packet }) {
    const [showAll, setShowAll] = useState(false)
    const [search, setSearch] = useState('')

    // Configure Fuse instance with search options
    const fuse = useMemo(() => {
        return new Fuse(packet?.templateOptions || [], {
            keys: ['label', 'task_category', 'project_type_scope', 'state'],
            threshold: 0.4,
            includeMatches: true,
            minMatchCharLength: 2,
        })
    }, [packet?.templateOptions])

    // Get filtered and highlighted results
    const filteredTemplates = useMemo(() => {
        if (!search) {
            const templates = packet?.templateOptions || []
            return showAll ? templates : templates.slice(0, 5)
        }
        const results = fuse.search(search).map(({ item, matches }) => ({
            ...item,
            _matches: matches,
        }))
        return showAll ? results : results.slice(0, 5)
    }, [search, showAll, packet?.templateOptions, fuse])

    return (
        <Card
            style={{
                marginBottom: 24,
                maxHeight: 'calc(100vh - 200px)',
                display: 'flex',
                flexDirection: 'column',
            }}
        >
            <Card.Header className='d-flex justify-content-between align-items-center'>
                <Card.Title as='h4' className='mb-0'>
                    Choose Template
                </Card.Title>
                <div style={{ width: '300px' }}>
                    <Form.InputGroup inputGroupMerge size='sm'>
                        <Form.InputGroup.Text prepend>
                            <i className='fal fa-magnifying-glass' />
                        </Form.InputGroup.Text>
                        <Form.Control
                            value={search}
                            onChange={({ target: { value } }) => setSearch(value)}
                            placeholder='Search templates'
                            ignoreGlobalDisable
                        />
                        {search.length > 0 && (
                            <Form.InputGroup.Text
                                append
                                className='cursor-pointer'
                                onClick={() => setSearch('')}
                            >
                                <i className='fal fa-xmark' />
                            </Form.InputGroup.Text>
                        )}
                    </Form.InputGroup>
                </div>
            </Card.Header>
            <ListGroup noBorders style={{ overflowY: 'auto', flexGrow: 1 }}>
                {filteredTemplates?.map((template) => (
                    <Template
                        key={template?.id}
                        packet={packet}
                        template={template}
                        onClick={() => {
                            packet.addTemplate(template?.id)
                        }}
                    />
                ))}
                {!showAll && packet?.templateOptions?.length > 5 && (
                    <ListGroup.Item
                        action
                        onClick={() => setShowAll(true)}
                        className='d-flex justify-content-center cursor-pointer'
                        style={{
                            position: 'sticky',
                            bottom: 0,
                            backgroundColor: 'white',
                            borderTop: '1px solid #dee2e6',
                        }}
                    >
                        Show All
                    </ListGroup.Item>
                )}
            </ListGroup>
        </Card>
    )
}

function Template({ template, onClick }) {
    // Function to highlight matched text
    const highlightText = (text, fieldKey) => {
        if (!text || !template._matches) return text

        // Only get matches for this specific field
        const matches = template._matches.filter((m) => m.key === fieldKey)
        if (matches.length === 0) return text

        let result = text
        // Process matches in reverse order to not invalidate indices
        matches
            .flatMap((match) => match.indices)
            .sort((a, b) => b[0] - a[0])
            .forEach(([start, end]) => {
                result =
                    result.slice(0, start) +
                    `<mark class="bg-warning">${result.slice(start, end + 1)}</mark>` +
                    result.slice(end + 1)
            })
        return <span dangerouslySetInnerHTML={{ __html: result }} />
    }

    return (
        <ListGroup.Item
            className='d-flex align-items-center cursor-pointer'
            action
            onClick={onClick}
        >
            <DocumentIcon type={'template'} className='mx-2 my-2 flex-shrink-0 fa-fw' />
            <div className='ms-2 w-100'>
                <div className='fw-bold text-dark'>
                    {template?.label ? (
                        highlightText(template?.label, 'label')
                    ) : (
                        <i className='text-body'>No template name</i>
                    )}
                </div>
                <div className='text-muted'>
                    {[
                        template?.task_category &&
                            highlightText(template?.task_category, 'task_category'),
                        template?.project_type_scope &&
                            highlightText(template?.project_type_scope, 'project_type_scope'),
                        template?.state && highlightText(template?.state, 'state'),
                    ]
                        .filter(Boolean)
                        .reduce(
                            (prev, curr, i) => [
                                ...prev,
                                i > 0 && <span key={`sep-${i}`}>, </span>,
                                <span key={i}>{curr}</span>,
                            ],
                            []
                        )}
                </div>
            </div>
        </ListGroup.Item>
    )
}
